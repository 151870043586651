@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient-text {
  /* background: linear-gradient(90deg, #ff7e5f, #feb47b, #ff7e5f); */
  background: linear-gradient(90deg, #3F00E7, #8A2BE2, #3F00E7);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient-animation 2s linear infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 200% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}